/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiSelect from "components/VuiSelect";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

function Tables() {
  const options = [
    { value: "simple", label: "Simple Interest" },
    { value: "compound", label: "Compound Interest" },
    
  ];
  const [principal, setPrincipal] = useState(0);
  const [rate, setRate] = useState(0);
  const [time, setTime] = useState(0);
  const [interestType, setInterestType] = useState('simple');
  const [result, setResult] = useState(0);

  useEffect(() => {
    const calculateInterest = () => {
      if (interestType === 'simple') {
        const simpleInterest = (principal * rate * time) / 100;
        setResult(simpleInterest);
      } else {
        const compoundInterest = principal * (Math.pow(1 + rate / 100, time) - 1);
        setResult(compoundInterest);
      }
    };
    calculateInterest();
  }, [principal, rate, time, interestType]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                    <VuiTypography variant="lg" color="white">
                      Interest Rate Calculator
                    </VuiTypography>
                  </VuiBox>
      <VuiBox component="form" role="form">
        <VuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7} xl={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                <Card>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                      <VuiTypography variant="lg" color="white">
                        Principal Amount:
                      </VuiTypography>
                    </VuiBox>
                    <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                    >
                    <VuiInput type="number" value={principal} onChange={(e) => setPrincipal(parseFloat(e.target.value))} fontWeight="500" />
                  </GradientBorder>
                  </VuiBox>

                  <VuiBox mb={1} ml={0.5}>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                      <VuiTypography variant="lg" color="white">
                        Rate (% per annum):
                      </VuiTypography>
                    </VuiBox>
                    <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                    >
                    <VuiInput type="number" value={rate} onChange={(e) => setRate(parseFloat(e.target.value))} fontWeight="500" />
                  </GradientBorder>
                  </VuiBox>

                  <VuiBox mb={1} ml={0.5}>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                      <VuiTypography variant="lg" color="white">
                      Time (years):
                      </VuiTypography>
                    </VuiBox>
                    <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                    >
                    <VuiInput type="number" value={time} onChange={(e) => setTime(parseFloat(e.target.value))} fontWeight="500" />
                  </GradientBorder>
                  </VuiBox>

                  <VuiBox mb={1} ml={0.5}>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                      <VuiTypography variant="lg" color="white">
                      Interest Type:
                      </VuiTypography>
                    </VuiBox>
                    <VuiSelect onChange={(e) => setInterestType(e.target.value)} value={interestType} options={options}>
                    </VuiSelect>
                  </VuiBox>

                  <VuiBox>
                    <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    {interestType === 'simple' ? 'Simple Interest:' : 'Compound Interest:'}{' '}
                    {result.toFixed(2)}
                    </VuiTypography>
                  </VuiBox>
                </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
