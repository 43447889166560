import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const VuiSelectRoot = styled(Select)(({ theme, ownerState }) => {
  const { palette } = theme;
  const { color } = ownerState;
  const { primary, secondary, white } = palette;

  const selectStyles = {
    "&.MuiInputBase-root": {
      "& .MuiSelect-select": {
        backgroundColor: white.main,
        color: primary.main,
        borderColor: secondary.main,
        borderRadius: "4px",
        padding: "8px 12px",
        "&:focus": {
          borderColor: secondary.main,
        },
      },
      "& .MuiSelect-icon": {
        color: primary.main,
      },
    },
  };

  return {
    ...selectStyles,
  };
});

const VuiSelect = React.forwardRef(({ color, size, options, ...rest }, ref) => (
  <VuiSelectRoot {...rest} ref={ref} color="white" ownerState={{ color, size }}>
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </VuiSelectRoot>
));

// Setting default values for the props of VuiSelect
VuiSelect.defaultProps = {
  size: "medium",
  color: "white",
  options: [],
};

export default VuiSelect;
